<template>
    <div class="bottom">
        您已经到底了
    </div>
</template>

<script>
export default {
name: "BottomTip"
}
</script>

<style scoped lang="scss">
$r: 750/16/1rem;
.bottom{
    margin-top: 30/$r;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #969799;
    font-size: 14px;
    line-height: 50px;
    text-align: center;
    text-align: center;
}
</style>