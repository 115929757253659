<template>
  <!-- 战队列表排行 -->
  <div class="team-wrap" v-if="team_template">
    <!--未入队开始-->
    <div class="not-team-header" v-if="userInfo.status_team.current_status === 5 || userInfo.status_team.current_status === -1" >
      <van-image  custom-class="not-team-header-bg" :src="require('../../../../assets/images/user/not-team-header-bg.png')" />
      <img src="~assets/images/user/not-team-header-icon.png" class="not-team-header-icon"/>
      <div class="team-header-tit">战队排行榜</div>
    </div>
    <!--未入队结束-->

    <!--已入队开始-->
    <div class="team-header" v-if="userInfo.status_team.current_status !== 5 && userInfo.status_team.current_status !== -1">
      <div class="team-header-cont">
        <div class="header-tit">我的战队</div>
        <div class="team-header-box" @click="goTeamShow(teamInfo)">
          <div class="team-info vertical-start" >
            <img :src="teamInfo.cover_picture && teamInfo.cover_picture ? teamInfo.cover_picture : 'https://resources.suantan.net/mini-program/public/tack-team-avatar.png'" class="avatar-img"/>
            <div class="team-info-box">
              <div class="team-tit white-space">{{ teamInfo.name ? teamInfo.name : '酸谈社群' }}</div>
              <div class="people-num vertical-end-box">
                <img src="~assets/images/user/team-people.png"/>
                <span>{{ teamInfo.member_number + teamInfo.leader_number }}</span>
              </div>
              <!--                            <div class="ranking-text" v-if="teamInfo.rank.rank === 0">当前排名：<text class="theme-color">{{ teamInfo.rank.rank }}</text>，与上一名相差<span class="theme-color">{{ teamInfo.rank.difference }}</span>经验值</div>-->
              <div class="ranking-text" >当前排名：<span class="theme-color">{{ teamInfo.rank && teamInfo.rank.rank }}</span><span v-if="teamInfo.rank && (teamInfo.rank.difference !==0) && teamInfo.rank.rank !== 1">，与上{{}}一名相差<span class="theme-color">{{ teamInfo.rank && teamInfo.rank.difference }}</span>经验值</span></div>
              <div class="static-text" v-if=" userInfo.status_team.current_status === 0 || userInfo.status_team.operate_status === 5">待审核</div>
            </div>
          </div>
        </div>
      </div>

      <div class="ranking-tit-box">
        <div class="ranking-tit">战队排行榜</div>
      </div>
    </div>
    <!--已入队结束-->

    <!--排名列表开始-->
    <div class="team-ranking-wrap">
      <!--导航样式开始-->
      <div class="screen-nav align-center">
        <div class="screen-nav-item vertical-center" >
          <div class="screen-nav-list theme-color" :class="[listActive === 'week' ? 'screen-nav-list-act' : '']" @click="switchList('week')">周榜</div>
          <div class="screen-nav-list theme-color" :class="[listActive === 'week' ? '' : 'screen-nav-list-act']" @click="switchList('all')">总榜</div>
        </div>
      </div>
      <!--导航样式结束-->

      <!--排名列表开始-->
      <div v-for="(item,index) in teams" :key="item.id" >
        <div class="rank-item" @click.stop="goTeamShow(item,index)" v-if="item.is_show == 'Y'">
          <div class="rank-list vertical-center" >
            <div class="rank-num">{{ item.rank.rank }}</div>
            <div class="user-avatar-box">
<!--              <img :src="item.cover_picture ? item.cover_picture : $confStatic.teamImg" class="user-avatar"/>-->
                <img :src="item.cover_picture && item.cover_picture ? item.cover_picture : 'https://resources.suantan.net/mini-program/public/tack-team-avatar.png'" class="user-avatar"/>
              <img v-if="index < 3" :src="require('../../../../assets/images/user/first'+index+'-icon.png')" class="tit-icon"/>
            </div>
            <div class="list-info">
              <div class="name white-space">{{ item.name }}</div>
              <div class="info-box vertical-center">
                <div class="experience-box vertical-end-box">
                  <img src="~assets/images/user/team-people.png"/>
                  <span class="experience-num">{{ item.member_number + item.leader_number }}</span>
                </div>
                <div class="experience-box vertical-end-box">
                  <img src="~assets/images/index/task.png"/>
                  <span class="experience-num">{{ $handle.tranNumber(item.total_experience, 3) }}</span>
                </div>
              </div>
              <!--加入按钮开始-->
                <!--<div class="add-button align-center" v-if="userInfo.status_team.current_status === 5 || (userInfo.status_team.current_status === -1 && userInfo.status_team.operate_status === 4)" @click.stop="showOverlay(item)">
                    <div class="vertical-center">
                        <img class="add-icon" src="~assets/images/index/add-icon.png"/> <span>申请加入</span>
                    </div>
                </div>-->
<!--              <div class="add-button align-center" v-if="userInfo.status_team.current_status === 5 || (userInfo.status_team.current_status === -1 && userInfo.status_team.operate_status === 4)" @click.stop="showOverlay(item)"><span class="add-icon">+</span> <span>申请加入</span></div>-->
              <!--加入按钮结束-->
            </div>
          </div>
        </div>
      </div>
      <BottomTip v-if="teams && teams.length > 6"></BottomTip>

        <!--排名列表结束-->
    </div>
    <!--排名列表结束-->

    <van-toast id="van-toast" />
  </div>
</template>

<script>
import BottomTip from "components/BottomTip";
import { ImagePreview } from 'vant';
export default {
  name: "sign",
  data(){
    return{
      teams:{},                  //战队排行
      teamInfo:{},               //加入的战队信息
      listActive: 'week',        //切换榜单
      team: {},                  //战队id
      loading: false,
      finished: false,
        team_template: false,
        qrcodeImg:['images/suantan-service.png'],
    }
  },
  created() {
    this.$user.getUserInfo()
    this.getTeamInfo(this.userInfo.team_id)
    this.getTeams()
  },
  methods:{
    // 获取指定战队信息
    getTeamInfo(teamId){
      let _this = this
      let url = _this.$api.Teams+`/${teamId}`
      this.finished = true;
      _this.$http.get(url,true).then( res => {
        if(res.data.success){
          this.loading = false;
          this.finished = false;
          _this.teamInfo = res.data.data
            _this.team_template = true
        }
      } )
    },
    // 点击进入战队详情
    goTeamShow(teamInfo,index){
      this.team = teamInfo
      this.$router.push('/user/team/show/' + teamInfo.id)
    },
    // 获取战队榜单
    getTeams(type = 'week'){
      let _this = this;
      let url = _this.$api.Teams + `?type=${type}`
      _this.$http.get(url,true).then( res => {
        if(res.data.success){
          _this.teams = res.data.data
          _this.teams.sort(function (i,j){
            return i.rank.rank - j.rank.rank
          })
        }
      } )
    },
    // 切换榜单
    switchList(value){
        this.teams = {}
      this.listActive = value
      if(this.listActive === 'week'){
        this.getTeams(this.listActive)
      }else if(this.listActive === 'all'){
        this.getTeams(this.listActive)
      }
    },
      // 点击图片
      clickImg(item, index){
          let img = this.qrcodeImg.map(item => {
              return this.$confStatic.ossCdn + item
          })
          ImagePreview({
              images: img,
              startPosition: index,
          })

      },

  },
  watch:{
    userInfo(val, oldVal){
      return val;
    }
  },
  computed:{
    userInfo(){
      return this.$store.getters.userInfo;
    }
  },
    components:{
        BottomTip
    }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/team/index.scss";
$r: 750/16/1rem;
.wrapper {
    height: 100%;
    .help-overlay{
        width: 600/$r;
        background: #FFFFFF;
        border-radius: 20/$r;
        padding-bottom: 42/$r;
        .help-title{
            width: 100%;
            background: #FF9E73;
            border-radius: 20/$r 20/$r 0/$r 0/$r;
            font-size: 30/$r;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 46/$r;
            text-align: center;
            padding: 42/$r 62/$r;
            box-sizing: border-box;
        }
        .help-body{
            .help-wechat{
                display: flex;
                white-space: nowrap;
                overflow-x: auto;
                .help-wechat-con{
                    margin-right: 46/$r;
                    .help-wechat-title{

                        font-size: 30/$r;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                        color: #333333;
                        line-height: 36/$r;
                        text-align: center;
                    }
                    .help-wechat-img{
                        width: 268/$r;
                        height: 268/$r;
                        margin: 38/$r 0 20/$r;
                        background: #E6E6E6;
                    }
                    &:first-child{
                        margin-left: 166/$r;
                    }
                    &:last-child{
                        padding-right: 166/$r;
                    }

                }
                &::-webkit-scrollbar {
                    display: none;
                }
            }
            .help-tip-con{
                flex-direction: column;
                .help-tip{
                    margin: 20/$r 0 36/$r;
                    font-size: 24/$r;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #FF6B29;
                    line-height: 36/$r;
                }
                .help-btn{
                    width: 248/$r;
                    height: 74/$r;
                    background: #FF6B29;
                    border-radius: 37/$r;
                    font-size: 32/$r;
                    line-height: 74/$r;
                    font-family: Source Han Sans CN;
                    font-weight: 300;
                    color: #FFFFFF;
                    text-align: center;
                }
            }

        }
    }
}
</style>
